<template>
  <div class="taxe-emission-list-page page-layout">
    <!-- La barre d'action -->
    <PageHeader
      title="Histoire de taxe sur vehicule société (émissions de CO₂)"
      :has-back="true"
      @back="goBack"/>
    <!-- La liste des calculators -->
    <sortable-list
      :items="data"
      :parameters="listParameters">
    </sortable-list>
  </div>
</template>

<script>
    import calculatorsModel from "../../../../model/calculators";
    import PageHeader from "@/components/PageHeader";

    export default {
        components : { PageHeader },
        data() {
            return {
                isDebug        : false,
                data           : [],
                listParameters : [{
                    name : "Username",
                    key  : "username",
                },
                    {
                        name : "Dernière MAJ",
                        key  : "date_updated",
                    },
                    {
                        name : "Emission min",
                        key  : "emission_min",
                    },
                    {
                        name : "Emission max",
                        key  : "emission_max",
                    },
                    {
                        name : "Coef",
                        key  : "coef",
                    },
                ],
            };
        },
        methods    : {
            goBack() {
                this.$router.push("/admin/calculators/taxe-vehicule-societe-emission/list");
            },
        },
        mounted() {
            calculatorsModel.getHistoryEmission().then(data => {
                var options = {
                    weekday  : "long",
                    year     : "numeric",
                    month    : "long",
                    day      : "numeric",
                    hour     : "numeric",
                    minute   : "numeric",
                    second   : "numeric",
                    timeZone : "Europe/Paris",
                };
                data.forEach(function(d, i) {
                    d.date_updated = new Date(d.date_updated).toLocaleDateString("fr-FR", options);
                    data[i]        = Object.assign({}, d, JSON.parse(d.old_data));
                });
                this.data = data;
            });
        },
    };
</script>

<style lang="scss" scoped>
  .impot-revenu-list {
    .debug-log {
      max-height: 200px;
      overflow-y: scroll;
    }
  }
</style>
